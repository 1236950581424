$(function() {
	Fancybox.bind('[data-fancybox]', {
		placeFocusBack: false,
		autoFocus: false,
		// Thumbs: {
		// 	type: 'modern',
		// 	showOnStart: false,
		// },
		Thumbs: false,
		Carousel: {
			Navigation: {
				prevTpl: `
					<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
						<path d="M38.0156 19.9999L2.01942 19.9992M2.01942 19.9992L2.01668 19.9992M2.01942 19.9992L19.2972 2.722M2.01942 19.9992L2.01732 20.0013L19.2958 37.2793" stroke="#F5F5F5" stroke-linejoin="bevel"/>
					</svg>
				`,
				nextTpl: `
					<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
						<path d="M2 20.0001L37.9962 20.0008M37.9962 20.0008L37.9989 20.0008M37.9962 20.0008L20.7185 37.278M37.9962 20.0008L37.9983 19.9987L20.7198 2.7207" stroke="#F5F5F5" stroke-linejoin="bevel"/>
					</svg>
				`,
			},
		},
	});
})