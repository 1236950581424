$(function () {
	if ($(window).width() > 1279) {
		$('.js-nav-sublist').mouseover(function () {
			$('.js-nav-sublist').not(this).removeClass('nav__item_active').find('.nav__dropdown').slideUp(0);
			$(this).addClass('nav__item_active').find('.nav__dropdown').slideDown(0);
		});

		$('.js-header').mouseleave(function () {
			$('.nav__dropdown').slideUp(0);
			$('.js-nav-sublist').removeClass('nav__item_active');
		});

		$('.nav__item:not(".js-nav-sublist")').mouseover(function () {
			$('.js-nav-sublist').removeClass('nav__item_active').find('.nav__dropdown').slideUp(0);
		});
	} else if ($(window).width() <= 1279) {
		$('.nav__link_dropdown').click(function (e) {
			e.preventDefault();
		});

		$('.js-nav-sublist').click(function () {
			let containerHeight = $('.header__content')[0].scrollHeight;

			$('.js-header-back').addClass('header__back_active');
			$(this).find('.nav__dropdown').addClass('nav__dropdown_active').css('height', containerHeight);
		});

		$('.js-header-back').click(function (e) {
			$(this).removeClass('header__back_active');
			$('.nav__dropdown').removeClass('nav__dropdown_active');
		});
	}
})