$(function () {
	let advantagesIndex = 2;
	let advantagesTimer;

	$('.js-advantages-side-link').click(function () {
		if (!$(this).hasClass('advantages__side-link_active')) {
			$('.js-advantages-side-link').removeClass('advantages__side-link_active');
			$(this).addClass('advantages__side-link_active');
			var data = $(this).data('advantages');
			$('.js-advantages-item').removeClass('advantages__item_active');
			$('.js-advantages-item[data-advantages=' + data + ']').addClass('advantages__item_active');
			advantagesIndex = data + 1;
			if (data === $('.js-advantages-side-link').length) {
				advantagesIndex = 1;
			}
			clearInterval(advantagesTimer);
			itemChange();
		}
	});

	function itemChange() {
		advantagesTimer = setInterval(itemTrigger, 10000);
	}
	
	function itemTrigger() {
		$('.js-advantages-side-link[data-advantages=' + advantagesIndex + ']').trigger('click');
	}

	itemChange();

	$('.js-advantages-heading').click(function () {
		$(this).toggleClass('advantages__item-heading_active').next().slideToggle();
	});
})