$(function () {
	let reviewsBottomSlides = $('.reviews__bottom-slider-item');
	
	$.each(reviewsBottomSlides, function () {
		let element = $(this);
		let reviewsBottomDescription = element.find('.reviews__bottom-slider-description');
		let reviewsBottomMore = element.find('.js-reviews-bottom-more');
		
		if (reviewsBottomDescription[0].scrollHeight < 49) {
			reviewsBottomDescription.next().css('display', 'none');
		}

		reviewsBottomMore.click(function (e) {
			e.preventDefault();
			reviewsBottomMore.toggleClass('active');
			reviewsBottomDescription.toggleClass('active');
		});
	});
})