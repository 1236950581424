$(function () {
  let lastScroll;
  let project = $('.js-project');

  if ($(window).width() > 1279 && project.length) {
    let count = 0;
    let projectTop = project.offset().top;

    $(window).scroll(function () {
      let currentScroll = $(window).scrollTop();

      if (currentScroll > projectTop && lastScroll < currentScroll && count < 1) {
        project.addClass('project_active');
        count += 1;
      }

      lastScroll = currentScroll;
    });
  }
})