$(function () {
	const projectSlider = new Swiper('.js-project-slider', {
		spaceBetween: 16,
		speed: 1200,
		breakpoints: {
			768: {
				spaceBetween: 26,
			},
			1280: {
				spaceBetween: 64,
			}
		},
		pagination: {
			el: '.project-pagination',
			clickable: true,
		},
	});

	const individualitySlider = new Swiper('.js-individuality-slider', {
		spaceBetween: 16,
		speed: 1200,
		breakpoints: {
			768: {
				spaceBetween: 26,
			},
			1280: {
				spaceBetween: 64,
			}
		},
		pagination: {
			el: '.individuality-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				if (index < 9) {
					return '<span class="' + className + '">' + '0' + (index + 1) + "</span>";
				}
				return '<span class="' + className + '">' + (index + 1) + "</span>";
			},
		},
	});

	const examplesSlider = new Swiper('.js-examples-slider', {
		slidesPerView: 'auto',
		spaceBetween: 10,
		speed: 1200,
		breakpoints: {
			1280: {
				spaceBetween: 30,
			},
			1600: {
				spaceBetween: 40,
			}
		},
		navigation: {
			nextEl: '.examples-button-next',
			prevEl: '.examples-button-prev'
		}
	});

	const reviewsSlider = new Swiper('.js-reviews-slider', {
		spaceBetween: 26,
		speed: 1200,
		breakpoints: {
			1280: {
				spaceBetween: 64,
			}
		},
		pagination: {
			el: '.reviews-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				if (index < 9) {
					return '<span class="' + className + '">' + '0' + (index + 1) + "</span>";
				}
				return '<span class="' + className + '">' + (index + 1) + "</span>";
			},
		},
	});

	const reviewsBottomSlider = new Swiper('.js-reviews-bottom-slider', {
		slidesPerView: 'auto',
		spaceBetween: 60,
		speed: 1200,
		breakpoints: {
			1280: {
				spaceBetween: 134,
			},
			1600: {
				spaceBetween: 160,
			}
		},
	});

	const projectDetailSlider = new Swiper('.js-project-detail-slider', {
		spaceBetween: 16,
		speed: 1200,
		breakpoints: {
			768: {
				spaceBetween: 26,
			},
			1280: {
				spaceBetween: 64,
			},
			1600: {
				spaceBetween: 60,
			}
		},
		pagination: {
			el: '.project-detail-pagination',
			type: 'fraction',
		},
		navigation: {
			nextEl: '.project-detail-button-next',
			prevEl: '.project-detail-button-prev'
		}
	});

	const locationTicker = new Swiper('.js-location-ticker', {
		slidesPerView: 'auto',
		speed: 10000,
		loop: true,
		allowTouchMove: false,
		autoplay: {
			delay: 0,
		},
	});

	const projectCardSlider = new Swiper('.js-project-card-slider', {
		spaceBetween: 16,
		speed: 1200,
		breakpoints: {
			768: {
				spaceBetween: 26,
			},
			1280: {
				spaceBetween: 0,
			}
		},
		pagination: {
			el: '.project-card-pagination',
			clickable: true,
		},
	});

	const blogDetailSlider = new Swiper('.js-blog-detail-slider', {
		spaceBetween: 16,
		speed: 1200,
		breakpoints: {
			768: {
				spaceBetween: 26,
			},
			1280: {
				spaceBetween: 16,
			}
		},
		pagination: {
			el: '.blog-detail-pagination',
			type: 'fraction',
		},
		navigation: {
			nextEl: '.blog-detail-button-next',
			prevEl: '.blog-detail-button-prev'
		}
	});
})