$(function () {
  let lastScroll;
  let header = $('.js-header');
  let scrollTop = $('.js-scroll-top');
  let portfolioSide = $('.js-portfolio-side');

  setTimeout(() => {
    header.removeClass('animate__animated animate__fadeInDown')
  }, 1000);

  $(window).scroll(function () {
    let currentScroll = $(window).scrollTop();

    if (currentScroll > 300) {
      header.addClass('header_white');
    }

    currentScroll > 1000 && lastScroll > currentScroll ? scrollTop.addClass('button_top_active') : scrollTop.removeClass('button_top_active');

    portfolioSide && lastScroll > currentScroll ? portfolioSide.css('top', 180) : portfolioSide.css('top', '');

    lastScroll > currentScroll ? header.addClass('header_scroll_up') : header.removeClass('header_scroll_up');
    currentScroll > 0 ? header.addClass('header_scroll') : header.removeClass('header_scroll header_scroll_up header_white');

    lastScroll = currentScroll;
  });

  $('.js-burger').click(function () {
    $(this).toggleClass('burger_active');
    if ($(this).hasClass('burger_active')) {
      $('body').removeClass('o-hidden');
    } else {
      $('body').addClass('o-hidden');
    }
    header.toggleClass('header_active');
    $('.header__content').toggleClass('header__content_active');
    $('body').toggleClass('o-hidden');
    $('.nav__dropdown').removeClass('nav__dropdown_active').css('height', '');
    $('.js-header-back').removeClass('header__back_active');
  });
})