$(function () {
  if ($(window).width() >= 1280) {
    let section = $('.spaces');

    $.each(section, function () {
      let elem = $(this);
      let button = elem.find('.spaces__button');
      let link = elem.find('.link');

      link.hover(function () {
        button.addClass('button_hide');
      }, function () {
        button.removeClass('button_hide');
      });

      elem.mousemove(function (e) {
        let mouseX = e.pageX;
        let mouseY = e.pageY;
        let block = elem[0].getBoundingClientRect();

        let activePointer = block.left <= mouseX && mouseX <= block.right && block.top <= mouseY && mouseY <= block.bottom;

        requestAnimationFrame(function () {
          if (!activePointer) {
            button.removeClass('d-none');
            button.css({
              left: Math.round(mouseX) + 'px',
              top: Math.round(mouseY) + 'px'
            });
          } else {
            button.addClass('d-none');
          }
        });
      });

      elem.mouseleave(function () {
        requestAnimationFrame(function () {
          button.addClass('d-none');
        });
      });
    });
  }
})