$(function() {
	let header = $('.js-header');
	const scrollWidth = window.innerWidth - $('body')[0].offsetWidth + 'px';

	function showScroll() {
		if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
			$('body').css('padding-right', '');
			header.css('padding-right', '');
		}
		$('body').removeClass('o-hidden');
	}

	function hideScroll() {
		if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
			$('body').css('padding-right', scrollWidth);
			header.css('padding-right', scrollWidth);
		}
		$('body').addClass('o-hidden');
	}

	$('.js-popup-up').click(function (e) {
		e.preventDefault();
		let modal = $(this).attr('href');
		$(modal).addClass('popup_active');
		if (modal != '#popup-sent') {
			hideScroll();
		}
	});

	$('.js-popup-close').click(function (e) {
		e.preventDefault();
		$('.popup').removeClass('popup_active');
		// $('form').trigger('reset');
		showScroll();
		if ($('.js-burger').hasClass('burger_active')) {
			$('body').addClass('o-hidden');
		}
	});

	// $(document).mousedown(function (e) {
	// 	let targetBlock = $('.popup_active .popup__wrap');
	// 	if (targetBlock.has(e.target).length === 0) {
	// 		$('.popup').removeClass('popup_active')
	// 		showScroll();
	// 	}
	// });

	// $(document).keydown(function (e) {
	// 	if (e.code === 'Escape') {
	// 		$('.popup').removeClass('popup_active');
	// 		showScroll();
	// 	}
	// });
})