$(function() {
  const fileDrop = document.querySelector('.js-file-drop');

  if (fileDrop) {
    const fileInput = document.querySelector('.js-file-input');
    let fileInputFiles = fileInput.files;
    const fileArr = Array.from(fileInputFiles);

    function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    fileDrop.addEventListener('dragover', preventDefaults);
    fileDrop.addEventListener('dragenter', preventDefaults);
    fileDrop.addEventListener('dragleave', preventDefaults);

    fileDrop.addEventListener('dragover', () => {
      fileDrop.classList.add('active');
    });

    fileDrop.addEventListener('dragleave', () => {
      fileDrop.classList.remove('active');
    });

    fileDrop.addEventListener('drop', handleDrop);

    fileInput.addEventListener('change', (e) => {
      files = e.target.files;

      handleFiles(files);
    });

    function handleDrop(e) {
      e.preventDefault();
      fileDrop.classList.remove('active');

      const files = e.dataTransfer.files;

      if (files.length) {
        fileInput.files = files;

        handleFiles(files);
      }
    }

    function handleFiles(files) {
      for (const file of files) {
        if (!isValidFileType(file)) {
          return
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          const fileItem = document.createElement('div');
          const fileBlock = document.querySelector('.js-file-block');

          fileItem.classList.add('file__item');
          fileItem.innerHTML = `
            <div class="file__item-name">${file.name} ${Math.floor((file.size / 1000000) * 10 ) / 10} МB</div>
            <div class="file__item-delete js-file-delete">
              <svg width="24" height="24">
                <use xlink:href="./img/sprites/sprite.svg#close"></use>
              </svg>
            </div>
          `;
          fileBlock.appendChild(fileItem);
        };

        fileArr.push(file);
        fileInputFiles = fileArr;
      }
    }

    function isValidFileType(file) {
      const allowedTypes = ['application/pdf'];
      return allowedTypes.includes(file.type);
    }

    $(document).on('click', '.js-file-delete', function (e) {
      fileArr.splice($(this).parent().index(), 1);
      $(this).parent().remove();
      fileInputFiles = fileArr;
    })
  }
})