import "%modules%/header/header";
import "%modules%/nav/nav";
import "%modules%/hero/hero";
import "%modules%/project/project";
import "%modules%/slider/slider";
import "%modules%/spaces/spaces";
import "%modules%/reviews/reviews";
import "%modules%/principles/principles";
import "%modules%/advantages/advantages";
import "%modules%/services/services";
import "%modules%/faq/faq";
import "%modules%/button/button";
import "%modules%/peculiarities/peculiarities";
import "%modules%/offer/offer";
import "%modules%/file/file";
import "%modules%/modal/modal";
import "%modules%/fancybox/fancybox";