$(function () {
  let lastScroll;
  let heroTitle = $('.js-hero-title');

  if ($(window).width() > 1340 && heroTitle.length) {
    let count = 0;
    let heroTitleTop = heroTitle.offset().top;
    let projectTitle = $('.js-project-title');
    let projectTitleTop = projectTitle.offset().top;
    let scroll = projectTitleTop - (heroTitleTop - 10);

    $(window).scroll(function () {
      let currentScroll = $(window).scrollTop();

      if (currentScroll > 0 && lastScroll < currentScroll && count < 1) {
        heroTitle.animate({
          top: scroll
        }, 1200);

        heroTitle.addClass('hero__title_scroll');

        setTimeout(() => {
          heroTitle.addClass('inactive');
          projectTitle.addClass('active');
        }, 1200);

        count += 1;
      }

      lastScroll = currentScroll;
    });
  }
})